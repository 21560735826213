import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { ThreeDots } from "react-loader-spinner";
import styled from "styled-components";
import { API_URL } from "../../../utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import { setShowStatus } from "../../../store/slices/statusSlice";
import { debounce } from "lodash";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import patientReportIcon from "../../../assets/patient-report-icon-blue.svg";
import Close from "../../../assets/remove-icon-red.svg";
import IProfileImageSmall from "../../../assets/profile-image-small.svg";
import moment from "moment";
import { CloseButton } from "../AddRecordsModal/AddRecords";
import closeIcon from "../../../assets/small-close-icon.svg";
import ConfirmIcon from "../../../assets/confirm.svg";

import CloseIconRed from "../../../assets/closeIcon.svg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Box, Checkbox, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
const PatientData = styled.p`
  overflow: hidden;
  margin: 0px;
  text-overflow: ellipsis;
  max-width: 220px;
  white-space: nowrap;
  font-size:16px;
  font-weight:400px;

`;
const SelectedListView = styled.div`
padding:10px;
margin-top:10px;
max-height:400px;
    overflow-y: auto;
    // border: 1px solid #ddd;
    display: grid;
    gap: 10px;
    box-sizing: border-box;
    overflow-x: hidden;

`;
const GenderSelection = styled.div`
margin-bottom:10px;
margin-top:10px;
margin-left:10px;
.MuiFormGroup-root{
    flex-direction: row;
    gap:10px;
}
    label{
    border: 1px solid #92D4E9;
    border-radius:5px;
    padding:0px 10px 0px 0px;
    }
    .Mui-checked{
    color:#0099CC !important;
    }
`;
const PasentImage = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;
const RecommendedBtn = styled.div`
  background: #ffcf66;
  width: 60%;
  text-align: center;
  padding: 5px 0;
  border-radius: 0px 0px 0px 12px;
`;
const ConfirmBtn = styled.button`
  background: #0099cc;
  padding: 5px;
  color: #fff;
  width: 40%;
  text-align: center;
  border-radius: 0px 0px 12px 0px;
  cursor: pointer;
border:none;

  &:disabled {
    background-color: #ccc;
   cursor: not-allowed;
border:none;

 }
`;
const CloseIcons = styled.div`
  position: absolute;
  right: 15px;
  top: 25px;
`;
const PatentsCardRecommended = styled.div`
  position: relative;
  max-width: 320px;
  width:100%;
`;
const PatentsCardRecommendedText = styled.div`
  border: 2px solid #ffcf66;
  border-bottom: 0px solid;
  border-radius: 12px 12px 0px 0px;
`;
const AddPasentBtn = styled.div`
  background-color: #ebf7fb;
  text-align: center;
  margin-top: 16px;
  border-radius: 5px;
  padding: 10px;
  .add-new-patient {
    background-color: #0099cc;
    border: 1px solid #0099cc;
    border-radius: 5px;
    padding: 10px 30px;
    color: #fff;
    font-size: 16px;
    fontwight: 500;
  }
`;
const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  z-index:9;
`;
const ListView = styled.div`
  position: absolute;
  top: 46px;
  width:100% !important;
  max-height: 360px;

  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 999;
  background-color: white;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  width: calc(100% - 27px);
`;

const SelectedItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 13px 9px;
  cursor: pointer;
  border: none;
  position: relative;
  font-size: 13px;
  .close-icon {
    position: absolute;
    right: 25px;
  }
`;
const ListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 13px 9px;
  cursor: pointer;
  border-bottom: none;
  font-size: 13px;
  border-bottom: 1px solid #e4e4e7;
  &:hover {
    background-color: rgb(235, 247, 251);
  }
`;

const ListItemPersonLogo = styled.div`
  font-size: 13px;
`;
const ListItemPersonDetail = styled.div`
  align-items: center;
  font-size: 13px;
  margin-left: 8px;
`;
const ListItemValue = styled.div`
  font-size: 14px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 238px;
  white-space: nowrap;
`;

const CreateNewButton = styled.button`
  width: 50%;
  align-self: center;
  display: flex;
  height: 35px;
  justify-content: center;
  align-items: center;
  background-color: rgb(122, 186, 86);
  margin-top: 7px;
  margin-bottom: 7px;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
`;
const SubmitButton = styled.button`
  width: 50%;
  margin: 2px auto;
  align-self: center;
  display: flex;
  height: 35px;
  justify-content: center;
  align-items: center;
  background-color: #7ABA56;
  border-color:#7ABA56;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 5px;
  font-size: 14px;
`;

const InputContainer = styled.div`
  position: relative;
  // display: flex;
  align-items: center;
`;

const SelectedCard = styled.div`
  width: 100%;
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #0099cc;
  border-radius: 8px;
  border: 1px solid #0099cc;
`;

const PatientCard = styled.div`
  padding: 20px 15px;
  box-shadow: 5px 1px 18px #ddd;
  border: 1px solid #E4E4E7;
  width: auto;
  align-self: center;
  min-height: 35px;
  align-items: center;
  background-color: #fff;
  margin-top: 7px;
  margin-bottom: 7px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
`;

const TextInput = styled.input`
  padding: 0 10px;
  font-size: 14px;
  box-sizing: border-box;
  width:100%;
  background-color: rgb(255, 255, 255);
  border: 1px solid #0099cc;
  border-radius: 8px;
  height: 45px;
  outline: none;
  background-image: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.021 10.0783L13.8764 12.9336L12.9337 13.8763L10.079 11.021C8.88092 11.9781 7.36178 12.4402 5.83361 12.3124C4.30544 12.1845 2.88424 11.4763 1.86188 10.3333C0.839532 9.19029 0.293637 7.69921 0.33631 6.16629C0.378984 4.63337 1.00699 3.17497 2.09135 2.09061C3.1757 1.00626 4.6341 0.378252 6.16702 0.335578C7.69994 0.292904 9.19102 0.8388 10.334 1.86115C11.477 2.8835 12.1852 4.30471 12.3131 5.83288C12.441 7.36105 11.9782 8.88019 11.021 10.0783ZM9.6837 9.58363C10.5412 8.6997 11.0146 7.51262 11.0006 6.28115C10.9866 5.04967 10.4864 3.87366 9.60898 3.00944C8.73157 2.14523 7.54811 1.66288 6.31657 1.66755C5.08502 1.67221 3.90525 2.16351 3.03441 3.03435C2.16357 3.90519 1.67228 5.08496 1.66761 6.3165C1.66295 7.54804 2.14529 8.7315 3.00951 9.60892C3.87372 10.4863 5.04974 10.9865 6.28121 11.0005C7.51268 11.0145 8.69976 10.5412 9.5837 9.68363L9.6837 9.58363Z" fill="%237ABA56"/></svg>');
  // background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" id="search-icon" width="24" height="24" viewBox="0 0 24 24"><path id="Path_547" data-name="Path 547" d="M0,0H24V24H0Z" fill="none"/><path id="Path_548" data-name="Path 548" d="M18.031,16.617,22.314,20.9,20.9,22.314l-4.282-4.283a9,9,0,1,1,1.414-1.414Zm-2.006-.742a7,7,0,1,0-.15.15l.15-.15Z" fill="%23a6b8bb"/></svg>');
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 24px;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right:45px;
  &::placeholder{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
   

  
`;
const TextInputFeild = styled.input`
  padding: 0 10px;
  width: calc(100% - 20px);
  font-size: 16px;
  font-weigth:400px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #92D4E9;
  border-radius: 8px;
  height: 38px;
  outline: none;
  margin-bottom: 8px;
  padding-left: 10px;

`;

const LoaderIcon = styled.div`
  position: absolute;
  right: 31px;
  height: 20px;
  width: 20px;
`;

const Name = styled.div`
  font-size: 16px;
  color: #0099cc;
  margin-bottom: 4px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap;
`;

const StyledSelect = styled(Select)`
  .MuiSelect-select,
  MuiInputBase-root {
    padding: 2px 10px 1px 10px;
    font-size: 14px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #0099cc;
    border-radius: 8px;
    height: 36px !important;
  }
  fieldset {
    border: none;
  }
`;

const PatientAttechedDropdown = ({
  type,
  setSelectedPatient,
  setSelectedDoctor,
  setSelectedDoctorItem = (data) => {
    return;
  },
  isAutoPopulate = { patient: false, doctor: false },
  autoPopulateData = {},
  setIsautoPopulate = (data) => {
    return;
  },
  setautoPopulateData = (data) => {
    return;
  },
  onConfirm = (data, type) => {
    return;
  },
  confirmLoading= false,
  sharingStatus="AVAILABLE",
  isDirectLinkEnabled=false,
  setAllowDirectLink=() => {
    return;
  },
  isAllowDirectLink=false
}) => {
  const dispatch = useDispatch();
  const statusReducer = useSelector((state) => state.status);
  const [cookies] = useCookies();
  const [inputValue, setInputValue] = useState("");
  const [searchedData, setSearchedData] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState(false);
  const [showListView, setShowListView] = useState(false);
  const [inputError, setInputError] = useState({ mobile: "", email: "" });
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryCodesArray, setCountryCodesArray] = useState([]);
  const [inputPatientItem, setInputPatientItem] = useState(null);
  const [recommendedPatientItem, setRecommendedPatientItem] = useState(null);


  const [inputDoctorItem, setInputDoctorItem] = useState([]);
  const [isDoctorItemSelected, setIsDoctorItemSelected] = useState(false);
  const [showAddPatientCard, setShowAddPatientCard] = useState(false);
  const [gender, setGender] = useState("");
  const [patientName, setPatientName] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [patientMobile, setPatientMobile] = useState("");
  const [apiError, setApiError] = useState("");

  const [isPatientAdded, setIsPatientAdded] = useState(false);

  // useEffect(() => {
  //   const lastShareDoctore = localStorage.getItem("lastShareDoctorItem");
  //   const item = (lastShareDoctore && JSON.parse(lastShareDoctore)) || false;
  //   if (item) {
  //     setSelectedDoctor({ id: item?.patient_fhir_id });
  //     setSelectedDoctorItem(item);
  //     setInputDoctorItem(item);
  //     setIsDoctorItemSelected(true);
  //     setInputValue("");
  //   }
  // }, []);

  useEffect(() => {
    try {
      const countryData = cookies.countryData ? cookies.countryData : null;
      if (countryData) {
        setSelectedCountry(countryData.code);
      }
    } catch (error) {
      console.error("Error parsing countryData cookie:", error);
    }
  }, [cookies.countryData]);

  useEffect(() => {
    if (selectedCountry === "IN") {
      setCountryCode("+91");
      setCountryCodesArray([
        { code: "US", dial_code: "+1" },
        { code: "IN", dial_code: "+91" },
        { code: "GB", dial_code: "+44" },
      ]);
    } else if (selectedCountry === "GB") {
      setCountryCode("+44");
      setCountryCodesArray([{ code: "GB", dial_code: "+44" }]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (
      isAutoPopulate.patient &&
      autoPopulateData?.patient_data?.patient_fhir_id
    ) {
      setInputError("");
      const patientData = {
        value: autoPopulateData?.patient_data.phone_number,
        label: autoPopulateData?.patient_data.name
          ? autoPopulateData?.patient_data?.phone_number &&
          autoPopulateData?.patient_data.phone_number.slice(-10) +
          " (" +
          autoPopulateData?.patient_data.name +
          ")"
          : autoPopulateData?.patient_data.phone_number &&
          autoPopulateData?.patient_data.phone_number.slice(-10),
        name: autoPopulateData?.patient_data.name,
        email: autoPopulateData?.patient_data?.email,
        gender: autoPopulateData?.patient_data?.gender,
        dob: autoPopulateData?.patient_data?.dob,
        patient_fhir_id: autoPopulateData?.patient_data.patient_fhir_id,
      };
      setSelectedPatient({
        id: autoPopulateData?.patient_data.patient_fhir_id,
      });
      setRecommendedPatientItem(patientData);
      setIsItemSelected(true);
      setInputValue("");
    } else if (isAutoPopulate?.patient) {
      setInputError("");
      setPatientEmail(autoPopulateData?.patient_data?.email || patientEmail);
      setPatientMobile(
        autoPopulateData?.patient_data?.phone_number || patientMobile
      );
      setPatientName(autoPopulateData?.patient_data?.name);
      setGender(
        autoPopulateData?.patient_data?.gender?.charAt(0)?.toUpperCase() +
        autoPopulateData?.patient_data?.gender?.slice(1)
      );
      setUserDob(autoPopulateData?.patient_data?.dob)
    }

    // if (isAutoPopulate.doctor && autoPopulateData?.doctor_data?.id) {
    //   const doctorData = {
    //     value: autoPopulateData?.doctor_data.phone,
    //     label: autoPopulateData?.doctor_data.name
    //       ? autoPopulateData?.doctor_data?.phone?.slice(-10) +
    //       " (" +
    //       autoPopulateData?.doctor_data.name +
    //       ")"
    //       : autoPopulateData?.doctor_data?.phone?.slice(-10),
    //     name: autoPopulateData?.doctor_data.name,
    //     email: autoPopulateData?.doctor_data?.email,
    //     gender: autoPopulateData?.doctor_data?.gender,
    //     dob: autoPopulateData?.doctor_data?.dob,
    //     patient_fhir_id: autoPopulateData?.doctor_data.id,
    //   };
    //   setSelectedDoctor({ id: autoPopulateData?.doctor_data.id });
    //   setSelectedDoctorItem(doctorData);
    //   setInputDoctorItem(doctorData);
    //   setIsDoctorItemSelected(true);
    //   setInputValue("");
    // }
  }, [isAutoPopulate, autoPopulateData]);

  const closeAutoPopulate = () => {
    setautoPopulateData({ ...autoPopulateData, patient_data: {} });
    setIsautoPopulate({ ...isAutoPopulate, patient: false });
    setSelectedPatient(null);
    setInputError("");
  };

  const allCountryCodes = [
    { code: "IN", dial_code: "+91" },
    { code: "US", dial_code: "+1" },
    { code: "GB", dial_code: "+44" },
  ];

  const loadNumbers = useCallback(
    async (inputValue) => {
      if (!inputValue || inputValue.length <= 3 || isItemSelected) {
        setIsSearching(false);
        return;
      }
      setIsSearching(true);
      const isNumericInput = /^\d+$/.test(inputValue);
      const isAlphabeticInput = /^[a-zA-Z\s]+$/.test(inputValue);
      const isEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inputValue);
      try {
        const url =
          type === "patient"
            ? `/patient?search=${encodeURIComponent(
              `${isNumericInput ? "" : ""}${inputValue}`
            )}`
            : inputValue.length 
              ? `/search/practitioner?phone=${encodeURIComponent(inputValue)}`
              : (isAlphabeticInput && inputValue.length > 3) || isEmail
                ? `/search/practitioner?name=${encodeURIComponent(inputValue)}`
                : null;

        if (!url) return;

        const token = cookies["access_token"];
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const method =
          url.includes("phone") || url.includes("name") ? "POST" : "GET";
        const payload =
          method === "POST"
            ? {
              search: isNumericInput ? `${""}${inputValue}` : inputValue,
            }
            : undefined;

        const response = await axios({
          method,
          url: `${API_URL}${url}`,
          data: payload,
          headers: config.headers,
        });

        let formattedData = [];
        if (type === "patient" && response?.data?.data?.length > 0) {
          console.log("response.data.data", response.data.data);
          formattedData = response.data.data.map((item) => ({
            value: item.phone_number,
            label: item.name
              ? item?.phone_number &&
              item.phone_number.slice(-10) + " (" + item.name + ")"
              : item.phone_number && item.phone_number.slice(-10),
            name: item.name,
            email: item?.email,
            gender: item?.gender,
            dob: item?.dob,
            patient_fhir_id: item.patient_fhir_id,
          }));
        } else if (type === "doctor" && response?.data?.result?.length > 0) {
          formattedData = response.data.result.map((item) => ({
            value: item.phone,
            label: item.name
              ? item?.phone?.slice(-10) + " (" + item.name + ")"
              : item?.phone?.slice(-10),
            name: item.name,
            email: item?.email,
            gender: item?.gender,
            dob: item?.dob,
            patient_fhir_id: item.id,
          }));
        }
        console.log("setSearchedData", formattedData);
        setSearchedData(formattedData);
      } catch (error) {
        console.error(error);
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "An unexpected error occurred";
        dispatch(
          setShowStatus({
            message: errorMessage,
            severity: "error",
            autoHide: true,
            autoHideIn: 6000,
          })
        );
      } finally {
        setIsSearching(false);
        setShowListView(true);
      }
    },
    [type, cookies, isItemSelected, dispatch, setShowStatus, countryCode]
  );

  const debouncedLoadNumbers = useCallback(debounce(loadNumbers, 500), [
    loadNumbers,
  ]);

  useEffect(() => {
    if (inputValue.length > 3 && !isItemSelected) {
      debouncedLoadNumbers(inputValue);
    } else {
      setIsSearching(false);
    }
  }, [inputValue, debouncedLoadNumbers, isItemSelected]);

  const handleChange = (e) => {
    const value = e.target.value
    setInputValue(value);
    setIsItemSelected(false);
    setIsDoctorItemSelected(false);
    setInputError("");
    if (!value) {
      setSearchedData(null);
      setIsSearching(false);
    }
  };

  const renderLoaderIcon = () => {
    return isSearching ? (
      <LoaderIcon>
        <ThreeDots
          height="20"
          width="20"
          color="#4fa94d"
          ariaLabel="loading-indicator"
        />
      </LoaderIcon>
    ) : null;
  };
  const [userDob, setUserDob] = useState("");
  const handleDobChange = (newValue) => {
    setUserDob(newValue);
  };
  const onListItemSelected = (item) => {
    console.log("item", item);
    setShowListView(false);
    setInputValue(item.value);
    if (type === "patient") {
      setSelectedPatient({ id: item.patient_fhir_id });
      setInputPatientItem(item);
      setIsItemSelected(true);
      setInputValue("");
    } else {

      // setSelectedDoctor({ id: item.patient_fhir_id });
      const inputDoctorItems = [...inputDoctorItem]
      inputDoctorItems.push(item);
      setInputDoctorItem(inputDoctorItems);
      setSelectedDoctorItem(inputDoctorItems);

      setIsDoctorItemSelected(true);
      setInputValue("");
    }
  };

  const renderSlectedtem = (item) => {
    return (
      <>
        {type === "patient" && recommendedPatientItem ?
          <PatentsCardRecommended>
            <CloseIcons
              onClick={() => {
                setRecommendedPatientItem(null)
                setInputPatientItem(null);
                  setIsItemSelected(false);
                  setInputValue("");
                  setSelectedPatient(null);
                  setSearchedData(null);
                  setautoPopulateData({ ...autoPopulateData, patient_data: {} });
                  setIsautoPopulate({ ...isAutoPopulate, patient: false });
              }}
            >
              <img src={CloseIconRed} />
            </CloseIcons>
            <PatentsCardRecommendedText>
              <Box
                display={"flex"}
                justifyContent={"start"}
                sx={{ gap: "16px", p: 2 }}
              >
                <PasentImage>
                  <img src={IProfileImageSmall}/>
                </PasentImage>
                {/* <ListItemPersonLogo>
                <img src={IProfileImageSmall} height={40} />
              </ListItemPersonLogo> */}
                <Box>
                  <Typography
                    variant="inherit"
                    fontSize={"16px"}
                              fontWeight={"400px"}
                    gutterBottom
                    sx={{ color: "#0099CC",
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px',
                    whiteSpace: 'nowrap'
                     }}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="body1" gutterBottom fontSize={"20px"}
                              fontWeight={"400px"} >
                    {item.value}
                  </Typography>
                  <PatientData>{item?.email}</PatientData>
                  <Typography
                    variant="body1"
                    gutterBottom
                    fontSize={'16px'}
                    fontWeight={'400px'}
                    sx={{ mt: 1, color: "#808080" }}
                  >
                    {item?.gender}
                    {item.dob &&
                      ", " +
                      moment().diff(moment(item.dob, "YYYY-MM-DD"), "years") +
                      " Years"}
                  </Typography>
                </Box>
              </Box>
            </PatentsCardRecommendedText>

            <Box display={"flex"}>
              <RecommendedBtn>Recommended</RecommendedBtn>
              <ConfirmBtn
              onClick={() => {
                onConfirm(item, 'patient')
              }}
             disabled ={confirmLoading[item?.patient_fhir_id]}>
              {confirmLoading[item?.patient_fhir_id] ?
                <CircularProgress size={18} color="inherit" /> :
                <>Confirm <img src={ConfirmIcon} /></>
              }
             </ConfirmBtn>
            </Box>
          </PatentsCardRecommended>
          :
          <PatentsCardRecommended>
            <CloseIcons
              onClick={() => {
                if (type === "patient") {
                  setInputPatientItem(null);
                  setIsItemSelected(false);
                  setInputValue("");
                  setSelectedPatient(null);
                  setSearchedData(null);
                  setautoPopulateData({ ...autoPopulateData, patient_data: {} });
                  setIsautoPopulate({ ...isAutoPopulate, patient: false });
                } else {
                  setInputDoctorItem(null);
                  setIsDoctorItemSelected(false);
                  // setSelectedDoctor(null);
                  setSearchedData(null);
                  setIsautoPopulate({ ...isAutoPopulate, doctor: false });
                  setautoPopulateData({ ...autoPopulateData, doctor_data: {} });
                }
              }}
            >
              <img src={CloseIconRed} />
            </CloseIcons>
            <PatentsCardRecommendedText>
              <Box
                display={"flex"}
                justifyContent={"start"}
                sx={{ gap: "16px", p: 2 }}
              >
                <PasentImage>
                  <img src={IProfileImageSmall} />
                </PasentImage>
                {/* <ListItemPersonLogo>
              <img src={IProfileImageSmall} height={40} />
            </ListItemPersonLogo> */}
                <Box>
                  <Typography
                    variant="inherit"
                    gutterBottom
                    sx={{ color: "#0099CC",
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px',
                    whiteSpace: 'nowrap'
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {item.value}
                  </Typography>
                  <PatientData>{item?.email}</PatientData>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ mt: 1, color: "#808080" }}
                  >
                    {item?.gender}
                    {item.dob &&
                      ", " +
                      moment().diff(moment(item.dob, "YYYY-MM-DD"), "years") +
                      " Years"}
                  </Typography>
                </Box>
              </Box>
            </PatentsCardRecommendedText>
            <Box display={"flex"}>
              <RecommendedBtn> {sharingStatus == "AVAILABLE" ? "Patient will be notified.":"Patient will not be notified."}</RecommendedBtn>
              <ConfirmBtn onClick={() => {
                console.log("item!!!!!",item);
                onConfirm(item, 'patient')
              }
              } disabled ={confirmLoading[item?.patient_fhir_id]}>
                {confirmLoading[item?.patient_fhir_id] ?
                <CircularProgress size={18} color="inherit" /> :
                <>Confirm <img src={ConfirmIcon} /></>
              }
              </ConfirmBtn>
            </Box>
          </PatentsCardRecommended>
        }
      </>
    );
  };
  const renderListItem = () => {
    return searchedData?.map((item) => {
      console.log("seracgh ", item);
      return (
        <ListItem onClick={() => onListItemSelected(item)}>
          <ListItemPersonLogo>
            <img src={IProfileImageSmall} height={32} />
          </ListItemPersonLogo>
          <ListItemPersonDetail>
            <Name>{item.name}</Name>
            <ListItemValue> {item.value}</ListItemValue>
            <ListItemValue> {item?.email}</ListItemValue>

            <span
              style={{
                fontSize: "14px",
                marginTop: "10px",
                textTransform: "capitalize",
              }}
            >
              {item?.gender}
              {item.dob &&
                ", " +
                moment().diff(moment(item.dob, "YYYY-MM-DD"), "years") +
                " Years"}
            </span>
          </ListItemPersonDetail>
        </ListItem>
      );
    });
  };

  const handleCreate = async () => {
    const isNumericInput = /^\d+$/.test(patientMobile);
    const isAlphabeticInput = /^[a-zA-Z\s]+$/.test(inputValue);
    const isEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(patientEmail);
    const numberReg = /^\d{10}$/;

    // if ( isNumericInput && !numberReg.test(patientMobile) && patientMobile.length !== 10) {
    //   setInputError({...inputError,mobile:"Invalid mobile number"});
    //   return;

    // }else{
    //   setInputError("")
    // }
    let isCountryCode =
      patientMobile && !/^\+[1-9]{1}[0-9]{3,14}$/.test(patientMobile);
    let isMobileNoLengthValid = patientMobile && patientMobile.length <= 11;

    if (
      (!isNumericInput || patientMobile.length !== 10) &&
      (isCountryCode || isMobileNoLengthValid)
    ) {
      setInputError({
        ...inputError,
        mobile: !/^\+[1-9]{1}[0-9]{3,14}$/.test(patientMobile)
          ? "Please include country code in mobile number"
          : "Invalid mobile number---",
      });
      return;
    } else {
      setInputError("");
    }

    if (patientEmail && !isEmail) {
      setInputError({ ...inputError, email: "Invalid email address" });
      return;
    } else {
      setInputError("");
    }
    try {
      setApiError("");
      const data = {
        name: patientName,
      };

      if (patientMobile) {
        data["contact_number"] = patientMobile
          ? `${isNumericInput && patientMobile.length == 10 ? "+91" : ""
          }${patientMobile}`
          : "";
      }

      if (patientEmail) {
        data["email"] = patientEmail;
      }
      if (gender) {
        data["gender"] = gender?.toLocaleLowerCase();
      }

      if (userDob) {
        data["date_of_birth"] = moment(userDob).format("YYYY-MM-DD");
      }

      const token = cookies["access_token"];
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setIsSearching(true);
      const response = await axios.post(`${API_URL}/patient`, data, config);

      if (response?.data?.data?.patient_id) {
        dispatch(
          setShowStatus({
            message: "Patient added successfully",
            severity: "success",
          })
        );
        const patientId = response?.data?.data?.patient_id;
        const addedPatientData = {
          value: response?.data?.data?.phone_number,
          label: response?.data?.data?.name
            ? response?.data?.data?.phone_number &&
            response?.data?.data?.phone_number.slice(-10) +
            " (" +
            response?.data?.data?.name +
            ")"
            : response?.data?.data?.phone_number &&
            response?.data?.data?.phone_number.slice(-10),
          name: response?.data?.data?.name,
          email: response?.data?.data?.email,
          gender: response?.data?.data?.gender,
          dob: response?.data?.data?.dob,
          patient_fhir_id: response?.data?.data?.patient_id,
        };

        // onListItemSelected(addedPatientData);
        onConfirm(addedPatientData,'patient').then((res)=>{
          setShowListView(false);
          setShowAddPatientCard(false);
        setIsSearching(false);
         setPatientEmail("");
        setPatientMobile("");
        setPatientName("");
        setGender("");
       
        })
        // setSelectedPatientId(patientId);
        // setSelectedPatient({ id: patientId });
        // setIsPatientAdded(true);
      }
    } catch (error) {
      console.log(error);
      setApiError(error?.response?.data?.message || "somthing went wrong");
    } finally {
      // setIsSearching(false);
    }
  };

  const handleChangeCountryCode = (event) => {
    setCountryCode(event.target.value);
  };

  const renderCountryCodeDropdown = () => {
    return (
      <StyledSelect
        value={countryCode}
        onChange={handleChangeCountryCode}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          width: "80px",
          marginRight: "8px",
        }}
      >
        {type === "patient" &&
          countryCodesArray.map((item) => (
            <MenuItem key={item.code} value={item.dial_code}>
              {item.dial_code}
            </MenuItem>
          ))}
        {type === "doctor" &&
          allCountryCodes.map((item) => (
            <MenuItem key={item.code} value={item.dial_code}>
              {item.dial_code}
            </MenuItem>
          ))}
      </StyledSelect>
    );
  };

  const renderCreateButton = () => {
    const isAlphabeticInput = /^[a-zA-Z\s]+$/.test(inputValue);
    const isEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inputValue);
    const isNumericInput = /^\d+$/.test(inputValue);
    const isMobileNo = inputValue && /^\+[\d]+$/.test(inputValue);
    console.log("kjhjkhk", isNumericInput, isMobileNo);

    const onClickCreateBtn = () => {
      setPatientEmail('')
      setPatientName('')
      setPatientMobile('')

      let result = isEmail
        ? setPatientEmail(inputValue)
        : isAlphabeticInput
          ? setPatientName(inputValue)
          : isNumericInput || isMobileNo
            ? setPatientMobile(inputValue)
            : setPatientName(inputValue);
      setShowAddPatientCard(true);
      return;
    };
    return (
      <CreateNewButton onClick={() => onClickCreateBtn()}>
        + Add New Patient
      </CreateNewButton>
    );
  };

  const renderList = () => {
    const isNumericInput = /^\d+$/.test(inputValue);
    const isEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inputValue);
    if (isSearching) {
      return renderLoaderIcon();
    } else if (!isSearching && searchedData?.length > 0) {
      return renderListItem();
    } else if (
      inputValue &&
      !isSearching &&
      searchedData?.length <= 0 &&
      type !== "doctor"
    ) {
      return renderCreateButton();
    }
  };

  const renderPatientInfo = () => {
    return (
      <PatientCard>
        {patientMobile && (
          <div
            style={{
              marginLeft: "10px",
            }}
          >
            <strong>Mobile No:</strong> {countryCode} {patientMobile}{" "}
          </div>
        )}
        {patientEmail && (
          <div
            style={{
              marginLeft: "10px",
            }}
          >
            <strong>Email</strong> {patientEmail}{" "}
          </div>
        )}

        <div
          style={{
            marginLeft: "10px",
          }}
        >
          <strong>Patient Name:</strong> {patientName}
        </div>
        <div
          style={{
            marginLeft: "10px",
          }}
        >
          <strong>Patient Gender:</strong> {gender}
        </div>
      </PatientCard>
    );
  };

  return (
    <Container>

      <InputContainer>
        {type === "patient" &&
          (!isItemSelected ? (
            <>
              {/* {!showAddPatientCard && renderCountryCodeDropdown()} */}
              <TextInput
                type="text"
                onChange={handleChange}
                placeholder="Name, mobile number or e-mail"
                value={inputValue}
                style={
                  // inputError
                  //   ? { border: "1px solid red" }
                  {
                    display:
                      showAddPatientCard || isAutoPopulate.patient
                        ? "none"
                        : "block",
                    border: "1px solid #E4E4E7"

                  }
                }
              />


              <PatientCard
                style={{ display: !showAddPatientCard ? "none" : "block" }}
              >
                {/* <div>
                    <CloseButton
                      src={closeIcon}
                      onClick={closeAutoPopulate}
                      style={{ position: "absolute", top: "5px", right: "1px" }}
                    />
                  </div> */}
                   <CloseIcons
              onClick={() => {
                setRecommendedPatientItem(null)
                setInputPatientItem(null);
                  setIsItemSelected(false);
                  setInputValue("");
                  setSelectedPatient(null);
                  setSearchedData(null);
                  setShowAddPatientCard(false);
                 
              }}
            >
              <img src={CloseIconRed} />
            </CloseIcons>
                <Box sx={{ mb: 3 }} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography
                    variant="inherit"
                    gutterBottom
                    sx={{ color: "#0099CC" }}
                  >
                    Add New Patient
                  </Typography>
                  {/* <img src={closeIcon} /> */}
                  {isDirectLinkEnabled && (
                    <Box display="flex" alignItems="center" mb={2} mr={5} >
                      <div>
                        <Checkbox
                          checked={isAllowDirectLink}
                          sx={{ padding: 0 }}
                          onChange={()=>setAllowDirectLink()}
                        />
                      </div>
                      <Box ml={1}>Allow Direct Link</Box>
                    </Box>
                  )}

                </Box>
                <strong>{/* {countryCode} {inputValue} */}</strong>
                <TextInputFeild
                  value={patientName}
                  placeholder="Patient name"
                  onChange={(e) => setPatientName(e.target.value)}
                  sx={{ marginTop: "10px", }}
                />

                <TextInputFeild
                  placeholder="Mobile number (With country code)"
                  value={patientMobile}
                  onChange={(e) => setPatientMobile(e.target.value.trim(" "))}
                  style={
                    inputError && inputError["mobile"]
                      ? { border: "1px solid red" }
                      : {
                        display: "block",
                        marginTop: "10px",
                      }
                  }
                  type="text"
                />
                {inputError && inputError["mobile"] && (
                  <ErrorMessage>{inputError["mobile"]}</ErrorMessage>
                )}
                <FormControl size="small" fullWidth sx={{
                  height: "40px", my: "10px"
                }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      disableFuture
                      label=""
                      value={userDob || null}
                      onChange={handleDobChange}
                      size="small"
                      fullWidth
                      InputProps={{
                        placeholder: "Date of Birth",
                      }}

                      renderInput={(params) => (
                        <TextField
                          sx={{
                            svg: { color: "#7BBA56" },
                            ".MuiInputBase-root": {
                              height: "40px",
                            },
                            ".MuiInputBase-input": {
                              borderColor: "#92D4E9",
                            },

                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#92D4E9",
                            },
                            "&:hover > .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#92D4E9",
                            },
                          }}
                          {...params}
                          helperText={null}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>

                <TextInputFeild
                  placeholder="E-mail"
                  value={patientEmail}
                  onChange={(e) => setPatientEmail(e.target.value.trim(" "))}
                  style={
                    inputError && inputError["email"]
                      ? { border: "1px solid red" }
                      : {
                        display: "block",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }
                  }
                />
                {inputError && inputError["email"] && (
                  <ErrorMessage>{inputError["email"]}</ErrorMessage>
                )}

                <GenderSelection>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={gender}
                      name="radio-buttons-group"
                       onChange={(event) => setGender(event.target.value)}

                    >
                      <FormControlLabel value="Male" control={<Radio />} label="Male" />
                      <FormControlLabel value="Female" control={<Radio />} label="Female" />
                    </RadioGroup>
                  </FormControl>
                </GenderSelection>
                {/* <StyledSelect
                  sx={{ width: "100%" }}
                  value={gender}
                  onChange={(event) => setGender(event.target.value)}
                  placeholder="Select Gender"
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </StyledSelect> */}
                <AddPasentBtn>
                  <SubmitButton
                    onClick={handleCreate}
                    disabled={
                      (!patientEmail && !patientMobile) ||
                        (patientMobile && patientMobile.length < 10)
                        ? true
                        : false
                    }
                  >
              {isSearching ?
                <CircularProgress size={18} color="inherit" /> :
                   " Add Patient"}
                  </SubmitButton>
                </AddPasentBtn>
                {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
              </PatientCard>
              {/* || patientMobile && patientMobile.length < 10  */}
              {/* {isPatientAdded && <ListView>{renderPatientInfo()}</ListView>} */}
              {isAutoPopulate.patient && (
                <>
                <PatientCard style={{ display: "block" }}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                <Typography
                    variant="inherit"
                    gutterBottom
                    sx={{ color: "#0099CC" }}
                    fontSize={16}
                    marginBottom={3}
                  >
                    Add New Patient
                  </Typography>
                  {isDirectLinkEnabled && (
                    <Box display="flex" alignItems="center" mb={2} mr={5} >
                      <div>
                        <Checkbox
                          checked={isAllowDirectLink}
                          sx={{ padding: 0 }}
                          onChange={()=>setAllowDirectLink()}
                        />
                      </div>
                      <Box ml={1}>Allow Direct Link</Box>
                    </Box>
                  )}
                  </Box>
                  <div>
                    <CloseButton
                      src={closeIcon}
                      onClick={closeAutoPopulate}
                      style={{ position: "absolute", top: "5px", right: "1px" }}
                    />
                  </div>
                  <TextInputFeild
                  value={patientName}
                  placeholder="Patient name"
                  onChange={(e) => setPatientName(e.target.value)}
                  sx={{ marginTop: "10px"}}
                />
                
                  <TextInputFeild
                    value={patientMobile}
                    sx={{marginBottom:'0px'}}
                    placeholder="Mobile number (With country code)"
                    onChange={(e) => setPatientMobile(e.target.value.trim(" "))}
                    style={
                      inputError && inputError["mobile"]
                        ? { border: "1px solid red" }
                        : {
                          display: "block",
                        }
                    }
                    type="text"
                    disabled={autoPopulateData?.patient_data?.id}
                  />

                  {inputError && inputError["mobile"] && (
                    <ErrorMessage>{inputError["mobile"]}</ErrorMessage>
                  )}
                  <FormControl size="small" fullWidth sx={{
                  height: "40px", paddingBottom:"8px"
                }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      disableFuture
                      label=""
                      value={userDob || null}
                      onChange={handleDobChange}
                      size="small"
                      fullWidth
                      InputProps={{
                        placeholder: "Date of Birth",
                      }}

                      renderInput={(params) => (
                        <TextField
                          sx={{
                            svg: { color: "#7BBA56" },
                            ".MuiInputBase-root": {
                              height: "40px",
                              borderRadius: "8px",
                            },
                            ".MuiInputBase-input": {
                              borderColor: "#92D4E9",
                            },

                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#92D4E9",
                            },
                            "&:hover > .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#92D4E9",
                            },
                          }}
                          {...params}
                          helperText={null}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  
                </FormControl>
                <TextInputFeild
                    value={patientEmail}
                    placeholder="E-mail"
                    onChange={(e) => setPatientEmail(e.target.value.trim(" "))}
                    style={
                      inputError && inputError["email"]
                        ? { border: "1px solid red" }
                        : {
                          display: "block",
                        }
                    }
                    disabled={autoPopulateData?.patient_data?.id}
                  />
                  {inputError && inputError["email"] && (
                    <ErrorMessage>{inputError["email"]}</ErrorMessage>
                  )}
                <GenderSelection>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={gender}
                      name="radio-buttons-group"
                      onChange={(event) => setGender(event.target.value)}
                    >
                      <FormControlLabel value="Male" control={<Radio />} label="Male" />
                      <FormControlLabel value="Female" control={<Radio />} label="Female" />
                    </RadioGroup>
                  </FormControl>
                </GenderSelection>
                  {!autoPopulateData?.patient_data?.id && (
                    <AddPasentBtn>
                    <SubmitButton
                      onClick={handleCreate}
                      disabled={
                        (!patientEmail && !patientMobile) ||
                          (patientMobile && patientMobile.length < 10)
                          ? true
                          : false
                      }
                    >
                        {isSearching ?
                <CircularProgress size={18} color="inherit" /> :
                   "Submit"}
                    </SubmitButton>
                    </AddPasentBtn>
                  )}
                  {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
                </PatientCard>
                </>
              )}
            </>
          ) : (
            <>{recommendedPatientItem ? renderSlectedtem(recommendedPatientItem) : renderSlectedtem(inputPatientItem)}</>
          ))}
        {type === "doctor" &&
          // (!isDoctorItemSelected ? (
          (true ? (
            <>
              {/* {renderCountryCodeDropdown()} */}
              <TextInput
                type="text"
                onChange={handleChange}
                placeholder="Name, mobile number or e-mail"
                value={inputValue}
                style={{
                  display:
                    isAutoPopulate.doctor && autoPopulateData?.doctor_data?.id
                      ? "none"
                      : "block",
                  border: "1px solid #E4E4E7"
                }}
              />
            </>

          ) : (
            <>{renderSlectedtem(inputDoctorItem)}</>
          ))}
      


        {isSearching && (
          <LoaderIcon>
            <ThreeDots
              height="20"
              width="20"
              color="#4fa94d"
              ariaLabel="loading-indicator"
            />
          </LoaderIcon>
        )}
      </InputContainer>

      {showListView && !showAddPatientCard && (
        <>
          <ListView>
            {renderList()}

            {/* <AddPasentBtn>
              <button className="add-new-patient">Add Patient</button>
            </AddPasentBtn> */}
          </ListView>
        </>

      )}
        {type == 'doctor' && inputDoctorItem && inputDoctorItem.length ? <>
          <SelectedListView>
            {inputDoctorItem && inputDoctorItem.length ? inputDoctorItem.map((item => {
              return <SelectedCard>
                <SelectedItem>
                  <ListItemPersonLogo>
                    <img src={IProfileImageSmall} height={40} />
                  </ListItemPersonLogo>
                  <ListItemPersonDetail>
                    <Name>{item.name}</Name>
                    <ListItemValue> {item.value}</ListItemValue>
                    <ListItemValue> {item?.email}</ListItemValue>
                    <span
                      style={{
                        fontSize: "14px",
                        marginTop: "10px",
                        textTransform: "capitalize",
                      }}
                    >
                      {item?.gender}{" "}
                      {item.dob &&
                        ", " +
                        moment().diff(moment(item.dob, "YYYY-MM-DD"), "years") +
                        " Years"}
                    </span>
                  </ListItemPersonDetail>
                  <img
                    className="close-icon"
                    src={Close}
                    height={18}
                    onClick={() => {
                      if (type === "patient") {
                        setInputPatientItem(null);
                        setIsItemSelected(false);
                        setInputValue("");
                        setSelectedPatient(null);
                        setSearchedData(null);
                        setautoPopulateData({ ...autoPopulateData, patient_data: {} });
                        setIsautoPopulate({ ...isAutoPopulate, patient: false });
                      } else {



                        const inputDoctorItems = [...inputDoctorItem]
                        const index = inputDoctorItems.findIndex(el => (el.patient_fhir_id || el.doctor_id) === (item.patient_fhir_id || item.doctor_id))
                        if (index !== -1) {
                          console.log("cureent item", item, inputDoctorItem, index)
                          inputDoctorItems.splice(index, 1);
                          setInputDoctorItem(inputDoctorItems);
                          setSelectedDoctorItem(inputDoctorItems);
                        }


                        // setInputDoctorItem(null);
                        // setIsDoctorItemSelected(false);
                        // setSelectedDoctor(null);

                        setSearchedData(null);
                        setIsautoPopulate({ ...isAutoPopulate, doctor: false });
                        setautoPopulateData({ ...autoPopulateData, doctor_data: {} });
                      }
                    }}
                  />
                </SelectedItem>
              </SelectedCard>
            })) : ''}
          </SelectedListView>
        </> : ""}

    </Container>
  );
};

export default PatientAttechedDropdown;
