import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  statusSelector,
  unsetShowStatus,
} from "../../../store/slices/statusSlice";

interface StatusState {
  showStatus: boolean;
  autoHideIn?: number; 
  autoHide: boolean;
  severity: "success" | "info" | "warning" | "error"; 
  message: string;
}

const SnackBar: React.FC = () => {
  const dispatch = useDispatch();
  // Use the selector directly to get the status from the state
  const status = useSelector(statusSelector);

  return (
    <Snackbar
      open={status?.showStatus}
      autoHideDuration={status?.autoHideIn}
      onClose={() => {
        if (status?.autoHide) {
          dispatch(unsetShowStatus());
        }
      }}
    >
      <Alert
        onClose={() => {
          dispatch(unsetShowStatus());
        }}
        severity={status?.severity}
        sx={{ width: "100%" }}
      >
        {status?.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
