import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox, Box } from "@mui/material";
import DeleteIcons from '../assets/delete-icon.svg';
import InfoIcon from '../assets/infoicon.svg';
import OHIFIcon from "../assets/ohifIcon.svg";
import styled from "styled-components";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import deleteIconGrey from "../assets/delete-icon-scan-grey.svg";


const SeriesTable = styled.div`
table tr:hover{
.ViewIcons{
  opacity:1;

}}
.ViewIcons{
display:flex;
align-items:center;
opacity:0;
gap:10px;
img{
 border:1px solid #808080;
  padding:3px;
  border-radius:2px;
  width:12px;
  cursor:pointer;
  
}
  }

`;
function createData(
  Number: number,
  Description: string,
  Instances: number,
) {
  return { Number, Description, Instances };
}

const rows = [
  createData(1, "Pre Contrast 5mm", 159),
  createData(1, "Pre Contrast 5mm", 237),
  createData(1, "Pre Contrast 5mm", 262),
  createData(1, "Pre Contrast 5mm", 305),
  createData(1, "Pre Contrast 5mm", 356),
];

export default function CustomSeriesTable(props: {
  index: number,
  expandedSeriesData: Array<any>,
  checkAllSeries: any,
  deleteMultipleSeries: any,
  seletAllDelete: any
  onSelectRowSeries: any
  fetchViewerUrls: any
}) {
  const { index, expandedSeriesData, seletAllDelete, deleteMultipleSeries, checkAllSeries, onSelectRowSeries, fetchViewerUrls } = props;
  return (
    <SeriesTable>
      {expandedSeriesData ? <TableContainer sx={{ height: "350px" }}>
        <> {expandedSeriesData?.length ?

          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Number</TableCell>
                <TableCell align="left">Description</TableCell>
                {/* <TableCell align="left"># Instances</TableCell> */}
                <TableCell align="left"># Modality</TableCell>
                <TableCell align="left">
                  <Box display={'flex'} alignItems={'center'}>
                    <Checkbox
                      checked={
                        seletAllDelete[index]?.length == expandedSeriesData?.length
                      }
                      onChange={() => checkAllSeries(index, expandedSeriesData)}
                    />
                    <Box sx={{ backgroundColor: "#F7EBE3", borderRadius: "5px", display: "flex", textAlign: 'center', padding: "4px" , cursor:"pointer"}}>
                      {seletAllDelete[index]?.length > 0 ? (
                        <img  src={DeleteIcons}
                          onClick={() =>
                            deleteMultipleSeries(
                              expandedSeriesData[0]["study_id"],
                              index,
                              expandedSeriesData[0]["store_id"]
                            )
                          }
                        />) : (
                        <img
                          src={DeleteIcons}
                        />
                      )}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="left">View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>


              <>
                {expandedSeriesData?.map((seriesItem: any, indexs: any) => {
                  return <TableRow
                    key={indexs}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {indexs + 1}
                    </TableCell>
                    <TableCell align="left">{seriesItem.description || "-"}</TableCell>
                    {/* <TableCell align="left">{seriesItem.instance_count}</TableCell> */}
                    <TableCell align="left">{seriesItem.modality}</TableCell>
                    <TableCell align="left">
                      <Checkbox
                        checked={
                          seletAllDelete[index]?.includes(
                            seriesItem.series_id
                          )
                            ? true
                            : false
                        }
                        onChange={() =>
                          onSelectRowSeries(index, seriesItem)
                        }

                      /></TableCell>
                    <TableCell align="left">
                      <Box className="ViewIcons">
                        <img src={OHIFIcon} onClick={() => fetchViewerUrls(seriesItem, "basic", "series")} />
                      </Box>
                    </TableCell>
                  </TableRow>

                })}
              </>
            </TableBody>
          </Table>
          :
          <div style={{ textAlign: "center", height: "150px", marginTop: "90px" }}>
            No Record Found
          </div>}
        </>
      </TableContainer>
        :
        <div style={{ textAlign: "center", height: "200px" }}>
          <SimpleLoader
            text={""}
            height="100%"
            size={30}
          />
        </div>}
    </SeriesTable>
  );
}
