import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import closeIcon from "../../../assets/close-icon.png"
import SimpleLoader from "../Loaders/SimpleLoader";
import styled from "styled-components";
import { VerticalSep } from "../../styled/Gaps";
import AtlassianDropdown from "../SearchDropdown/AtlassianDropdown";
import PatientAttechedDropdown from "../SearchDropdown/PatientAttechedDropdown";
import { useEffect, useState } from "react";
import DownArrow from "../../../../src/assets/down-arrow-circle.svg";
import CustomShareCard from "../SearchDropdown/CustomShareCard";
import axios from "axios";
import { API_URL } from "../../../utilities/constants";
import { useCookies } from "react-cookie";
export const Dropdownlist = styled.div`
  padding: 2px 10px;
  background: transparent;
  
`;
const ArrowImageInstance = styled.img`
  width: 12px;
  height: 12px;
  padding: 8px;
  cursor: pointer;
`;
const ViewerButtonMenu = styled.button`
  background-color: #fff;
  border: 1px solid #E4E4E7;
  color:#808080;
  padding: 7px 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
  width:100%;
  position: relative;
  transition: 0.3s;
  img {
    mix-blend-mode: exclusion;
    width: 9px;
  }
  // &:hover {
  //   img {
  //     transform: rotate(180deg);
  //   }
  //   .custom-modal {
  //     display: block;
  //   }
  // }
  .custom-modal {
    background: #fff;
    width: 100%;
    z-index:99;
    top: 100%;
    box-shadow: 5px 3px 10px #ddd;
    left: 0;
    border-radius: 7px;
    display: none;
    position: absolute;
    overflow: hidden;
    border: 1px solid #d7f0f7;
    min-width: 130px;
  }
`;
const SelectDoctorCard = styled.div`
width:46%;
border:1px solid #E4E4E7;
border-radius:8px;
min-height: 370px;
.input-search-box{
  border-radius:8px 8px 0px 0px;
  background:#F8F8F8;
  padding:10px;
}
`;
const TestForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextFieldLabel = styled.div`
  color: #808080;
  font-size: 16px;
  margin-bottom: 10px;
`;
export interface EditTestProps {
  open: boolean;
  testId: string | null;
  title?: string | null | undefined;
  discription?: string | null
  closeHandler: () => void;
  onDelete?: () => void;
  isLoading: boolean;
  isLoaderPopup?: boolean;
  isAutoPopulate: any;
  autoPopulateData: any;
  setIsautoPopulate: any;
  setautoPopulateData: any;
  setSelectedPatient: any;
  setSelectedDoctor: any;
  setSelectedDoctorItem: any;
  selectedDoctorItem:any;
  setConfirmLoading:any;
  confirlAll:any;
  isSendLoading:boolean;
  isAllowOthersDirectLink:boolean;
  setAllowOthersDirectLink:any;
  isDirectLinkOthersEnabled:boolean;

}

interface Doctor {
  doctor_id: string;
  name: string;
  phone: string | null;
  email: string | null;
}

const ShareDoctorDialog = ({
  open,
  closeHandler,
  title,
  discription,
  onDelete,
  isLoading,
  isLoaderPopup,
  isAutoPopulate,
  autoPopulateData,
  setIsautoPopulate,
  setautoPopulateData,
  setSelectedPatient,
  setSelectedDoctor,
  setSelectedDoctorItem,
  selectedDoctorItem,
  setConfirmLoading,
  confirlAll,
  isAllowOthersDirectLink,
  setAllowOthersDirectLink,
  isDirectLinkOthersEnabled

}: EditTestProps) => {

  const [selectedDoctors, setSelectedDoctors] = useState<any>([]);
  const [selectedDoctorsList, setSelectedDoctorsList] = useState<Doctor[]>([]);
  const [groups, setGroups] = useState<{ label: string; value: string, numberOfDoctors: number }[]>([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [groupInfo, setGroupInfo] = useState<any>(null);
  const [isGroupOpen, setGroupOpen] = useState<boolean>(false);
  const [isSendLoading, setSendLoading] = useState<boolean>(false);


  const [cookies] = useCookies();

  const setShareDoctor = () => {
    setSendLoading(true)
    // setSelectedDoctor([...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem])
    //setSelectedDoctorItem([...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem]);
    let doctorIds = [...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem].map(
      (doctor: any) => doctor.doctor_id || doctor.patient_fhir_id
    );

    setTimeout(() => {
      confirlAll(doctorIds).then((res:any) => {
        if ([...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem] && [...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem]?.length) {
          localStorage.setItem(
            "lastShareDoctorItem",
            JSON.stringify([...selectedDoctors,...selectedDoctorsList,...selectedDoctorItem])
          );
        }
        setConfirmLoading({})
        closeHandler()
    setSendLoading(false)
      })
    }, 2000);

  };

  const setlastShareDoctor = (data: any) => {
    setSelectedDoctors(data);

  };

  useEffect(() => {
    if (open) fetchGroups();
  }, [open])


  const onRemove = (doctorId: any) => {
    console.log("doctorId",doctorId);
    
    const selectedDoctorItems = [...selectedDoctorsList]
    const index = selectedDoctorItems.findIndex(el => el.doctor_id === doctorId)
    if (index !== -1) {
      selectedDoctorItems.splice(index, 1);
      setSelectedDoctorsList(selectedDoctorItems);
    }
  }

  const handleGroupChange = async (groupId: any) => {
    setGroupOpen(false)
    setSelectedGroup(groupId);
    await fetchDoctors(groupId);
  };

  const fetchGroups = () => {
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/doctor/group`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(endpointUrl, config)
      .then((response: any) => {
        if (response.data.status === "success" && response.data.data) {
          const groupOptions = response.data.data.map((group: any) => ({
            label: group.group_name,
            value: group.group_id,
            numberOfDoctors: group.number_of_doctors
          }));
          setGroups(groupOptions);
        } else {
          alert("Something went wrong!!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchDoctors = async (groupId: string) => {
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/doctor/group/${groupId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(endpointUrl, config);
      if (response.data.status === "success" && response.data.data) {
        setGroupInfo(response.data.data.group_info);
        setSelectedDoctorsList(response.data.data.doctors_list);
      } else {
        alert("Something went wrong!!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth={'lg'}
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      // sx={{ padding: "100px" }}
    >



      <DialogTitle
        style={{
          justifyContent: "flex-start",
          color: "#0099CC",
          margin: "5px 30px 0",
          fontWeight: "600"
        }}
      >
        {title}
        <img onClick={closeHandler} height={50} width={50} style={{ cursor: "pointer", position: "absolute", right: " 0", top: "2px" }} src={closeIcon} />
      </DialogTitle>
      <DialogContent
        style={{ justifyContent: "flex-start", margin: "0px 30px" }}
      >
        <div style={{ marginTop: isLoaderPopup ? "59px" : "", minHeight: "470px" }}>

          <Box display={"flex"} justifyContent={"space-between"}>
            <SelectDoctorCard>
              <div className="input-search-box">
                <PatientAttechedDropdown
                  type={"doctor"}
                  isAutoPopulate={isAutoPopulate}
                  autoPopulateData={autoPopulateData}
                  setIsautoPopulate={setIsautoPopulate}
                  setautoPopulateData={setautoPopulateData}
                  setSelectedPatient={setSelectedPatient}
                  setSelectedDoctor={setSelectedDoctor}
                  setSelectedDoctorItem={(data) =>
                    setlastShareDoctor(data)
                  }
                />
              </div>


            </SelectDoctorCard>
            <SelectDoctorCard>
              <div className="input-search-box">
                <ViewerButtonMenu onClick={() => setGroupOpen(!isGroupOpen)}>
                  Select group of doctors
                  <ArrowImageInstance style={{ transform: isGroupOpen ? "rotate(180deg)" : "" }} src={DownArrow} />
                </ViewerButtonMenu>
              </div>

              <>
                {isGroupOpen && <div className="custom-modal">
                  {groups.map((group) => (
                    <Dropdownlist onClick={() => handleGroupChange(group.value)}>
                      <CustomShareCard isGroup={true} data={group} onRemove={onRemove}/>
                    </Dropdownlist>
                  ))}
                </div>}


                <>
                  {selectedGroup === "" || selectedDoctorsList.length === 0 && (
                    <p style={{ marginLeft: "20px" }}>No doctors present in this group</p>
                  )}
                </>

                <>
                  {groupInfo && !isGroupOpen && (
                    <div>
                      {/* <CustomShareCard isGroup={true} data={groupInfo}/> */}

                      {selectedDoctorsList.length === 0 ? (
                        <p>No doctors present in this group</p>
                      ) : (selectedDoctorsList.map((doctor) => (<Dropdownlist>
                        <CustomShareCard isGroup={false} data={doctor} onRemove={onRemove} />

                      </Dropdownlist>))
                      )}
                    </div>
                  )}
                </>

              </>

            </SelectDoctorCard>

          </Box>
        </div>
      </DialogContent>
     
      { isDirectLinkOthersEnabled ? (
                    <Box display="flex" justifyContent={"center"} alignItems="center" mb={2} ml={5} mr={4}>
                      <div>
                        <Checkbox
                          checked={isAllowOthersDirectLink}
                          sx={{ padding: 0 }}
                          onChange={() =>setAllowOthersDirectLink(!isAllowOthersDirectLink)}
                        />
                      </div>
                      <Box ml={1}>Allow Direct Link</Box>
                    </Box>
                  ):null}
      <DialogActions
        style={{
          justifyContent: "center",
          padding: "0px 20px 20px 10px",
          margin: "0px 30px",
        }}
      >
       
        {!isLoaderPopup && <>
          
          <Button disabled={isLoading || isSendLoading} onClick={setShareDoctor} sx={{ color: "#FFF", minWidth: "150px", borderRadius: "8px", height: "44px" }} variant="contained">
            {isSendLoading ? (
                          <CircularProgress size={26} color="inherit" />
                        ) : (
                          "Share"
                        )}
          </Button>
          
        </>
        }
      </DialogActions>
      <VerticalSep size={10} />
    </Dialog>
  );
};

export default ShareDoctorDialog;
