import {
  API_URL,
  COLOR_PRIMARY,
  COLOR_PRIMARY_LIGHT,
  COLOR_SECONDARY,
  COLOR_VARIANT,
  DEVICE,
  FONT_FAMILY_GENERAL,
  HEADER_HEIGHT,
  TEXT_COLOR_PRIMARY,
} from "../../utilities/constants";
import styled from "styled-components";
import logo from "../../assets/HemeCloud Labs.svg";
import homeIcon from "../../assets/home-nav-icon.svg";
import reportsIcon from "../../assets/reports.svg";
import doctorIcon from "../../assets/doctor-icon.svg";
import scansIcon from "../../assets/scans-icon.svg";
import incentivesIcon from "../../assets/incentives-icon.png";
import logoutIcon from "../../assets/logout-icon.svg";
import patientsIcon from "../../assets/patients.svg";
import mobileMenuIcon from "../../assets/menu-mobile-icon.svg";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { HorizontalSep } from "../styled/Gaps";
import { useState } from "react";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import LoggedInUser from "../data/LoggedInUser/LoggedInUser";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies } from "react-cookie";
import { useSelector, useDispatch } from "react-redux";
import { setShowStatus, unsetShowStatus } from "../../store/slices/statusSlice";

const StickyHeader = styled.div`
  background-color: #F5FBFC;
  color: #333;
  z-index: 1;
  height: ${HEADER_HEIGHT}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 48px;
  @media ${DEVICE.mobileL} {
    padding: 0px 15px;
  }
`;

const MobileHeader = styled.div`
  background-color: #fff;
  color: #333;
  z-index: 1;
  height: ${HEADER_HEIGHT + 1}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 48px;
  @media ${DEVICE.mobileL} {
    padding: 0px 15px;
  }
`;

const LogoImageCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const LogoImage = styled.img`
  width: 295px;
  height: 48px;
  @media ${DEVICE.laptopL} {
    width: 245px;
    }
  @media ${DEVICE.tablet} {
    height: 30px;
  }
`;

const NavTabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  
   @media ${DEVICE.laptopL} {
  gap:0px;
  }
  @media ${DEVICE.laptop} {
    display: none;
  }
`;

const SmallTabs = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileNav = styled.div`
  display: none;
  @media ${DEVICE.laptop} {
    display: flex;
  }
`;

const MobileTabs = styled.div`
  @media ${DEVICE.laptop} {
    position: absolute;
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    top: 0px;
    left: 0px;
    z-index: 1000;
  }
`;

const TABS = [
  {
    label: homeIcon,
    path: "/home",
  },
  {
    label: "Patients",
    path: "/patients",
  },
  {
    label: "Doctors",
    path: "/doctors",
  },
  {
    label: "Scans",
    path: "/scans",
  },
  {
    label: "Configurations",
    path: "/pacs-and-logs",
  },
  {
    label: "Templates",
    path: "/templates",
  },
  {
    label: "Help",
    path: "/help",
  }
];

const MOBILE_TABS = [
  ...TABS,
  // { icon: incentivesIcon, label: "INCENTIVES", path: "/profile" },
  { icon: logoutIcon, label: "LOGOUT", path: "/login" },
];

const FixedHeader = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileTabsSeen, setMobileTabsSeen] = useState<boolean>(false);
  const showHideMobileTabs = () => {
    setMobileTabsSeen((mobileTabsSeen) => !mobileTabsSeen);
  };

  const shouldHideNavLinks = location.pathname === '/' || location.pathname === '/home';

  const handleLogout = () => {
    dispatch(
      setShowStatus({
        message: "Logging you out...",
        severity: "warning",
      })
    );
    fetch(`${API_URL}/lab_portal/auth/logout`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${cookies["access_token"]}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          localStorage.removeItem("isNewAlreadyLoaded");
          localStorage.removeItem("selectedCachedColumns");
          console.log("Logged out!");
          removeCookie("access_token");
          dispatch(unsetShowStatus());
          navigate("/login");
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  return (
    <>
      <StickyHeader>
        <LogoImageCont>
          <LogoImage src={logo} />
        </LogoImageCont>
        {!shouldHideNavLinks && (
          <NavTabs>
            {TABS.map((tab, i) => {
              return (
                <NavLink
                  key={tab.path}
                  style={({ isActive, isPending }) => {
                    return {
                      textDecoration: "none",
                      color: `${isActive ? '#FFFFFF' : '#000000'}`,
                      fontSize: "16px",
                      backgroundColor: `${isActive ? '#0099CC' : '#F5FBFC'}`,
                      borderRadius: `${isActive ? '8px' : '0'}`,
                      padding: "14px 16px",
                    };
                  }}
                  to={tab.path}
                >
                  {tab.label === homeIcon ? (
                    <img src={homeIcon} alt="Home" style={{ height: '24px' }} /> // Display home icon
                  ) : (
                    <div>{tab.label}</div>
                  )}
                </NavLink>
              );
            })}
          </NavTabs>
        )}
        <HorizontalSep size={50} />

        <LoggedInUser />
        <MobileNav>
          <IconButton onClick={showHideMobileTabs}>
            <img src={mobileMenuIcon} />
          </IconButton>
        </MobileNav>
      </StickyHeader>
      {mobileTabsSeen ? (
        <MobileTabs>
          <MobileHeader>
            <LogoImageCont>
              <LogoImage src={logo} />
            </LogoImageCont>
            <MobileNav>
              <IconButton onClick={showHideMobileTabs}>
                <CloseIcon sx={{ color: COLOR_PRIMARY }} />
              </IconButton>
            </MobileNav>
          </MobileHeader>
          <LoggedInUser />
          <SmallTabs>
            {MOBILE_TABS.map((tab, i) => {
              return (
                <NavLink
                  key={tab.path}
                  style={({ isActive, isPending }) => {
                    return {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignSelf: "stretch",
                      textDecoration: "none",
                      color: TEXT_COLOR_PRIMARY,
                      fontSize: "14px",
                      backgroundColor: `${
                        isActive ? COLOR_PRIMARY_LIGHT : "none"
                      }`,
                      borderBottom: `0.2px solid ${COLOR_VARIANT}`,
                      padding: "20px 20px",
                    };
                  }}
                  to={tab.path}
                  onClick={() => {
                    if (tab.path == "/login") {
                      handleLogout();
                    } else {
                      showHideMobileTabs();
                    }
                  }}
                >
                  {tab.label === homeIcon ? (
                    <img src={homeIcon} alt="Home" style={{ height: '24px' }} />
                  ) : (
                    <div>{tab.label}</div>
                  )}
                </NavLink>
              );
            })}
          </SmallTabs>
        </MobileTabs>
      ) : null}
    </>
  );
};

export default FixedHeader;
