import UploadFiles from "../components/common/UploadFiles/UploadFiles";
import FixedHeader from "../components/structure/FixedHeader";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_LIGHT,
  COLOR_VARIANT,
  DEVICE,
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from "../utilities/constants";
import ReplaceReport from "../components/common/ReplaceReport/ReplaceReport";
import { useEffect, useState } from "react";
import FixedFooter from "../components/structure/FixedFooter";
import EditAddTest from "../components/common/EditTest/EditTest";
import { useCookies } from "react-cookie";
import DeleteSure from "../components/common/Dialog/DeleteSure";
import CustomDialog from "../components/common/Dialog/CustomDialog";
import { Alert, Snackbar } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { unsetShowStatus } from "../store/slices/statusSlice";
import WhatsappSure from "../components/common/Dialog/WhatsappSure";
import AddEditDoctor from "../components/common/AddEditDoctor/AddEditDoctor";
import CustomDialogLocal from "../components/common/Dialog/CustomDialogLocal";
import SingleReportDetails from "../components/common/SingleReportDetails/SingleReportDetails";
import AddEditPatient from "../components/common/AddEditPatient/AddEditPatient";

const MainBody = styled.div`
  background-color: ${COLOR_PRIMARY_LIGHT};
  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT});
  padding: 35px 20px;
  // overflow-y: auto;
  @media ${DEVICE.laptop} {
    padding: 0px 18px;
  }
`;
const Root = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [showReplaceReport, setShowReplaceReport] = useState<boolean>(false);
  const [showSingleReportDetails, setShowSingleReportDetails] = useState<boolean>(false);
  const [showEditTest, setShowEditTest] = useState<boolean>(false);
  const [showDeleteTest, setShowDeleteTest] = useState<boolean>(false);
  const [showReportDialog, setShowReportDialog] = useState<boolean>(false);
  const [showReportDialogLocal, setShowReportDialogLocal] =
    useState<boolean>(false);
  const [showWhatsappDialog, setShowWhatsappDialog] = useState<boolean>(false);
  const [showDoctorsDialog, setShowDoctorsDialog] = useState<boolean>(false);
  const [showPatientDialog, setShowPatientDialog] = useState<boolean>(false);
  const statusReducer = useSelector((state: any) => state.status);
  const dispatch = useDispatch();

  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  // If there is no access_token saved in cookies
  // redirect to login
  // Else if path / -> redirect to /home
  useEffect(() => {
    if (!cookies["access_token"]) {
      navigate("/login");
    } else {
      if (location.pathname == "/") {
        navigate("/home");
      }
    }
  }, []);

  const matchSearchParams = (paramName: string, value: string | boolean) => {
    let paramValue = searchParams.get(paramName);
    if (paramValue && paramValue == value) {
      return true;
    }
    return false;
  };

  const getSearchParam = (paramName: string) => {
    let paramValue = searchParams.get(paramName);
    return paramValue;
  };

  const removeSearchParam = (paramName: string) => {
    if (searchParams.has(paramName)) {
      searchParams.delete(paramName);
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (searchParams) {
      if (matchSearchParams("replace", "true")) {
        setShowReplaceReport(true);
      } else {
        setShowReplaceReport(false);
      }
      if (matchSearchParams("singleReportDetails", "true")) {
        setShowSingleReportDetails(true);
      } else {
        setShowSingleReportDetails(false);
      }
      if (matchSearchParams("editTest", "true")) {
        setShowEditTest(true);
      } else {
        setShowEditTest(false);
      }
      if (matchSearchParams("addTest", "true")) {
        setShowEditTest(true);
      }
      if (matchSearchParams("deleteTest", "true")) {
        setShowDeleteTest(true);
      }
      if (matchSearchParams("whatsapp", "true")) {
        setShowWhatsappDialog(true);
      }
      if (matchSearchParams("viewReport", "true")) {
        setShowReportDialog(true);
      } else {
        setShowReportDialog(false);
      }
      if (matchSearchParams("viewReportLocal", "true")) {
        setShowReportDialogLocal(true);
      } else {
        setShowReportDialogLocal(false);
      }

      // Doctor's dialog
      if (matchSearchParams("addEditDoctors", "true")) {
        setShowDoctorsDialog(true);
      } else {
        setShowDoctorsDialog(false);
      }
      
      // Patient dialog
      if (matchSearchParams("addEditPatient", "true")) {
        setShowPatientDialog(true);
      } else {
        setShowPatientDialog(false);
      }
    }
  }, [searchParams]);
  return (
    <>
      <FixedHeader />
      <MainBody>
        <Outlet />
        {showReplaceReport ? (
          <ReplaceReport
            open={showReplaceReport}
            reportId={getSearchParam("reportId")}
            closeHandler={() => {
              removeSearchParam("replace");
              removeSearchParam("reportId");
              setShowReplaceReport(false);
            }}
          />
        ) : (
          ""
        )}
        {showSingleReportDetails ? (
          <SingleReportDetails
            open={showSingleReportDetails}
            reportId={getSearchParam("reportId")}
            closeHandler={() => {
              removeSearchParam("singleReportDetails");
              removeSearchParam("reportId");
              setShowSingleReportDetails(false);
            }}
          />
        ) : (
          ""
        )}
        {showEditTest ? (
          <EditAddTest
            open={showEditTest}
            testId={getSearchParam("testId")}
            testName={getSearchParam("testName")}
            testPrice={getSearchParam("testPrice")}
            closeHandler={() => {
              removeSearchParam("editTest");
              removeSearchParam("testId");
              removeSearchParam("deleteTest");
              removeSearchParam("testPrice");
              removeSearchParam("testName");
              removeSearchParam("addTest");
              setShowEditTest(false);
            }}
          />
        ) : (
          ""
        )}
        {showDeleteTest ? (
          <DeleteSure
            open={showDeleteTest}
            testId={getSearchParam("testId")}
            testName={getSearchParam("testName")}
            testPrice={getSearchParam("testPrice")}
            closeHandler={() => {
              removeSearchParam("testId");
              removeSearchParam("deleteTest");
              removeSearchParam("testName");
              setShowDeleteTest(false);
            }}
          />
        ) : (
          ""
        )}

        {showReportDialog ? (
          <CustomDialog
            itemId={getSearchParam("reportId")}
            itemName={getSearchParam("reportName")}
            closeDialog={() => {
              removeSearchParam("viewReport");
              removeSearchParam("reportId");
              removeSearchParam("reportName");
              setShowReportDialog(false);
            }}
          />
        ) : (
          ""
        )}

        {showReportDialogLocal ? (
          <CustomDialogLocal
            itemBlobUrl={getSearchParam("reportBlobUrl")}
            itemName={getSearchParam("reportName")}
            closeDialog={() => {
              removeSearchParam("viewReportLocal");
              removeSearchParam("reportBlobUrl");
              removeSearchParam("reportName");
              setShowReportDialogLocal(false);
            }}
          />
        ) : (
          ""
        )}

        {showWhatsappDialog ? (
          <WhatsappSure
            open={showWhatsappDialog}
            reportId={getSearchParam("reportId")}
            reportName={getSearchParam("reportName")}
            type={getSearchParam("sendType")}
            closeHandler={() => {
              removeSearchParam("whatsapp");
              removeSearchParam("reportId");
              removeSearchParam("reportName");
              removeSearchParam("sendType");
              setShowWhatsappDialog(false);
            }}
          />
        ) : (
          ""
        )}

        {showDoctorsDialog ? (
          <AddEditDoctor
            open={showDoctorsDialog}
            testId={getSearchParam("testId")}
            testName={getSearchParam("testName")}
            testPrice={getSearchParam("testPrice")}
            closeHandler={() => {
              removeSearchParam("addEditDoctors");
              setShowDoctorsDialog(false);
            }}
          />
        ) : (
          ""
        )}
        
        {showPatientDialog ? (
          <AddEditPatient
            open={showPatientDialog}
            testId={getSearchParam("testId")}
            testName={getSearchParam("testName")}
            testPrice={getSearchParam("testPrice")}
            closeHandler={() => {
              removeSearchParam("addEditPatient");
              setShowPatientDialog(false);
            }}
          />
        ) : (
          ""
        )}
        <Snackbar
          open={statusReducer.showStatus}
          autoHideDuration={statusReducer?.autoHideIn}
          onClose={() => {
            if (statusReducer.autoHide) {
              dispatch(unsetShowStatus());
            }
          }}
        >
          <Alert
            onClose={() => {
              dispatch(unsetShowStatus());
            }}
            severity={statusReducer.severity}
            sx={{ width: "100%" }}
          >
            {statusReducer.message}
          </Alert>
        </Snackbar>
      </MainBody>
      <FixedFooter />
    </>
  );
};

export default Root;
