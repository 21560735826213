import { Button, Pagination } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from 'react-redux'
import styled from "styled-components";
import { ShimmerTable } from "react-shimmer-effects";
import CustomTable from "../components/common/CustomTable/CustomTable";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import SearchBox from "../components/common/SearchBox/SearchBox";
import UserDropdown from "../components/common/UserDropdown/UserDropdown";
import NoData from "../components/common/UserMessages/NoData";
import { HorizontalSep, VerticalSep } from "../components/styled/Gaps";
import IProfileImageSmall from "../assets/profile-image-small.svg";
import { setSelectedPatient } from "../store/slices/patient.slice";
import {
  API_URL,
  FONT_FAMILY_GENERAL,
  DEVICE,
} from "../utilities/constants";
import IDobIcon from '../assets/dob-icon.svg';
import IGenderIcon from '../assets/gender-icon.svg';
import editIcon from "../assets/edit-icon.svg";
import IEmailIcon from "../assets/email.svg";
import IPhoneIcon from "../assets/phone.svg";

const Container = styled.div`
  flex-grow: 1;
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
  align-items: center;
  justify-content: space-between;
`;
const Header = styled.div`
  font-size: 24px;
  color: #0099CC;
  font-weight: 600;
`;
const HeaderLeft = styled.div`
`;
const PatientContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
`;
const PatientCard = styled.div`
  width: 330px;
  min-height: 129px;
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
`;
const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
`;
const EditContainer = styled.div`
  display: flex;
  align-items: center;
`;
const EditText = styled.div`
  font-size: 16px;
  color: #7ABA57;
`;
const PhoneNumber = styled.div`
  font-size: 14px !important;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px
`;
const EmailText = styled.div`
    font-size: 14px !important;
    color: #000000;
    max-width: 200px;   
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;    
`;

const Name = styled.div`
  font-size: 16px;
  color: #0099CC;
  margin-bottom: 4px;

`;
const ProfileSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 24px;
`;
const ProfileImage = styled.img`
  height: 48px;
  width: 48px;
`;
const EmptyListMessage = styled.div`
  margin-top: 100px;
  color: red;
  display: flex;
  justify-content: center;
`;
const PatientInfo = styled.div`
`;
const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
`;

const EmailItem = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;
  margin-top: 3px;
`;
const AddTempWrapper = styled.div`
  margin-left: 10px;
  @media ${DEVICE.laptop} {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 0px;
  }
`;
const SearchSection = styled.div`
  flex-basis: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @media ${DEVICE.mobileL} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
const SearchTempWrapper = styled.div`
  flex-basis: 60%;
  @media ${DEVICE.laptop} {
    width: 100%;
  }
`;
const GenderImage = styled.img`
`;
const DobImage = styled.img`
`;
const EmailImage = styled.img`
  height: 14px;
  margin-top: 4px;
`;
const EditIcon = styled.img`
`;
const GenderText = styled.div`
  color:#808080;
  font-size: 16px;
  font-family: ${FONT_FAMILY_GENERAL};
`;
const DobText = styled.div`
  color:#808080;
  font-size: 16px;
  font-family: ${FONT_FAMILY_GENERAL};
`;
const DobDate = styled.span`
`;
const DobYears = styled.span`
  color: #808080;
  font-family: ${FONT_FAMILY_GENERAL};
`;

const capitalizeFirstLetter = string => {
  if (!string || typeof string !== 'string') return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Patients = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();
  const patientsPerPage = 10;
  const initialUrl = `/patient?limit=${patientsPerPage}`;
  const pageNumber = searchParams.get("page");
  const searchParam = searchParams.get("search");
  const dispatch = useDispatch();
  const [patientsData, setPatientsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(searchParam || "");
  const [endpointUrl, setEndpointUrl] = useState(initialUrl);
  const [nextUrl, setNextUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isInnerLoading, setIsInnerLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [pageNumberNew, setPageNumberNew] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(false)

  const totalPages = Math.ceil(totalCount / patientsPerPage);

  useEffect(() => {
    fetchPatientsData();
  }, [endpointUrl, searchTerm]);

  useEffect(() => {
    setSearchTerm(searchParam || "");
    // setCurrentPage(1);
  }, [searchParam]);

  const convertDataToTableFormat = (inputData) => {
    const outputData = inputData.map((obj, i) => {
      return {
        id: obj["id"],
        patient_fhir_id: obj["patient_fhir_id"],
        appUserPhone: obj["phone_number"],
        appUserName: obj["name"],
        gender: obj["gender"],
        dob: obj.dob == null ? null : moment(obj["dob"])
          .local()
          .format("DD-MMM-YYYY"),
      };
    });
    return outputData;
  };

  const handleOnSearchChange = (textValue) => {
    if (textValue !== searchTerm) {
      setPatientsData([])
      setSearchParams({ page: "1", search: textValue });
    }
  };

  const handlePatientClick = (id, phone_number, name, gender, dob) => {
    navigate("/patient-records");
  };

  const fetchPatientsData = () => {
    setIsLoading(true)
    const token = cookies["access_token"];
    const url = `${API_URL}/patient?search=${encodeURIComponent(
      searchTerm
    )}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data
        ) {
          const newPatientData = response.data.data;
          setPatientsData([...newPatientData]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
    <Container>
      <HeaderWrapper>
        <HeaderLeft>
          <Header>
          {`Patients ${patientsData?.length ? "("+patientsData?.length+")":''}`}
          </Header>
        </HeaderLeft>
        {/* <HeaderRight> */}
          <SearchSection>
            <HorizontalSep size={20} />
            <SearchTempWrapper>
              <SearchBox
                placeholderText="Search by name or mobile number"
                searchParamText={searchTerm}
                searchHandler={(textValue) =>
                  handleOnSearchChange(textValue)
                }
              />
            </SearchTempWrapper>
            <AddTempWrapper>
              <Button
                sx={{ color: "#FFF", height: "45px" }}
                variant={"contained"}
                onClick={() => {
                  if (!searchParams.has("replace")) {
                    searchParams.delete("patient_fhir_id");
                    searchParams.append("addEditPatient", "true");
                    setSearchParams(searchParams);
                  }
                }}
              >
                Add New
              </Button>
            </AddTempWrapper>
          </SearchSection>
        {/* </HeaderRight> */}
      </HeaderWrapper>
      {isLoading ? (
          <ShimmerTable row={5} />
        ) : (
          <>
          {patientsData?.length > 0 ? (
            <PatientContainer>
            {patientsData.map( patient => {
              const { id, name, gender, phone_number, dob, patient_fhir_id,email } = patient;
              const showGender = gender != null;
              const showDob = dob != null && dob !== '';
              return (
                <PatientCard
                  key={id}
                  onClick={() => {
                    dispatch(setSelectedPatient({ id: patient_fhir_id }));
                    navigate(`patientId?name=${patient_fhir_id}`, {
                      state: { id: patient_fhir_id },
                    });
                  }}
                >
                  <InnerContainer>
                    <ProfileSection>
                      <ProfileImage
                        src={IProfileImageSmall}
                        alt="ProfileImage"
                      />
                      <PatientInfo>
                        {name && <Name>{capitalizeFirstLetter(name)}</Name>}
                        {phone_number && (
                          <EmailItem>
                            <EmailImage
                              src={IPhoneIcon}
                              alt="phone"
                            ></EmailImage>
                            <EmailText>{phone_number}</EmailText>
                          </EmailItem>
                        )}
                        {email && (
                          <EmailItem>
                            <EmailImage
                              src={IEmailIcon}
                              alt="email"
                            ></EmailImage>
                            <EmailText>{email}</EmailText>
                          </EmailItem>
                        )}

                        {gender && (
                          <DetailItem>
                            <GenderImage
                              src={IGenderIcon}
                              alt="Gender"
                            ></GenderImage>
                            <GenderText>
                              {capitalizeFirstLetter(gender)}
                            </GenderText>
                          </DetailItem>
                        )}
                        {dob && (
                          <DetailItem>
                            <DobImage src={IDobIcon} alt="DOB"></DobImage>
                            <DobText>
                              <DobDate>
                                {moment(dob).format("DD-MMM-YYYY")}
                              </DobDate>
                              {/* <DobYears> ({moment().diff(moment(dob, 'YYYY-MM-DD'), 'years')} Years)</DobYears> */}
                            </DobText>
                          </DetailItem>
                        )}
                      </PatientInfo>
                    </ProfileSection>
                    <EditContainer
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!searchParams.has("replace")) {
                          searchParams.delete("addEditPatient");
                          searchParams.delete("patient_fhir_id");
                          searchParams.append("addEditPatient", "true");
                          searchParams.append(
                            "patient_fhir_id",
                            patient["patient_fhir_id"]
                          );
                          setSearchParams(searchParams);
                        }
                      }}
                    >
                      <EditIcon src={editIcon} style={{ marginRight: "5px" }} />
                      <EditText>Edit</EditText>
                    </EditContainer>
                  </InnerContainer>
                </PatientCard>
              );
            })}
          </PatientContainer>
          ) : (
            <EmptyListMessage>No patients data found</EmptyListMessage>
          )}
        </>

    )}
  </Container>
  </>
  );
};

export default Patients;
