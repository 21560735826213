import React from "react";
import {
  Box,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
} from "@mui/material";
import styled from "styled-components";
import CloseIcons from "../assets/removeicon.svg";
import Documents from "../assets/reporticon.svg";
// import DropdownIcon from "../assets/dropdownicons";

const ReportTitle = styled.div`
font-size: 12px;
font-weight: 400;
line-height: 16.34px;
text-align: left;
display: block;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width:120px;
cursor:pointer;

`;
const ReportBox = styled.div`
display:flex;
gap:10px;
max-height:320px;
overflow-y:auto;
`;
const ReportDocument = styled.div`
  background-color: #f8f8f8;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  img {
    width: 70px;
    margin: auto;
  }
`;
const PdfBtn = styled.button`
  background-color: #2faed7;
  border-radius: 0px 0px 10px 10px;
  color: #fff;
  text-align: center;
  margin-top: auto;
  padding: 8px 5px;
  border:none;
  cursor:pointer;
`;
const Closebtn = styled.button`
cursor:pointer;
background-color:transparent;
border:none;
`
const ReportCard = styled.div`
  width:145px;
  padding: 10px;
`;

const DropdownIconButton = (props) => {
  const { className } = props
  return (<Box className={className} >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="5" fill="#CC8552" />
      <path d="M11.9832 13.2768L8.20119 9.49285C8.06543 9.36695 7.88612 9.29862 7.70101 9.30224C7.51589 9.30587 7.3394 9.38116 7.20867 9.51227C7.07794 9.64339 7.00317 9.82011 7.0001 10.0052C6.99702 10.1904 7.06588 10.3695 7.19219 10.5048L11.4762 14.7918C11.6061 14.9214 11.781 14.9959 11.9645 14.9998C12.1479 15.0037 12.3258 14.9368 12.4612 14.8128L16.7752 10.5128C16.8417 10.4465 16.8945 10.3678 16.9306 10.281C16.9666 10.1943 16.9853 10.1013 16.9854 10.0074C16.9856 9.91348 16.9672 9.82045 16.9314 9.73362C16.8956 9.64679 16.843 9.56786 16.7767 9.50135C16.7104 9.43483 16.6316 9.38203 16.5449 9.34596C16.4582 9.30989 16.3652 9.29125 16.2712 9.29111C16.1773 9.29097 16.0843 9.30933 15.9975 9.34515C15.9106 9.38096 15.8317 9.43353 15.7652 9.49985L11.9832 13.2768Z" fill="white" />
    </svg>
  </Box>)
}

const   DropdownAvailableIconButton = (props) => {
  const { className } = props;
  return (
    <Box className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="5" fill="#7ABA56" />
        <path
          d="M11.9832 13.2768L8.20119 9.49285C8.06543 9.36695 7.88612 9.29862 7.70101 9.30224C7.51589 9.30587 7.3394 9.38116 7.20867 9.51227C7.07794 9.64339 7.00317 9.82011 7.0001 10.0052C6.99702 10.1904 7.06588 10.3695 7.19219 10.5048L11.4762 14.7918C11.6061 14.9214 11.781 14.9959 11.9645 14.9998C12.1479 15.0037 12.3258 14.9368 12.4612 14.8128L16.7752 10.5128C16.8417 10.4465 16.8945 10.3678 16.9306 10.281C16.9666 10.1943 16.9853 10.1013 16.9854 10.0074C16.9856 9.91348 16.9672 9.82045 16.9314 9.73362C16.8956 9.64679 16.843 9.56786 16.7767 9.50135C16.7104 9.43483 16.6316 9.38203 16.5449 9.34596C16.4582 9.30989 16.3652 9.29125 16.2712 9.29111C16.1773 9.29097 16.0843 9.30933 15.9975 9.34515C15.9106 9.38096 15.8317 9.43353 15.7652 9.49985L11.9832 13.2768Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};
function ReportDetailsCard({ mediaArray, setMediaArray, unlink, isSent ,recordStatusChange}) {


  const handleChange = (event, fileId) => {
    if(isSent){
      recordStatusChange("report",fileId,event.target.value)
    }else{
      const index = mediaArray.findIndex((item) => item.file_id === fileId);
    if (index !== -1) {
      const updatedItem = {
        ...mediaArray[index],
        status: event.target.value,
      };
      const updatedArray = [
        ...mediaArray.slice(0, index),
        updatedItem,
        ...mediaArray.slice(index + 1),
      ];
      setMediaArray([...updatedArray]);
    }
    }
    
  };
  return (
    <ReportBox>

      {mediaArray && mediaArray.length ? mediaArray.map((media) => {
        return (
          <div style={{width:"145px"}}>
          <ReportCard>
            <Box display={"flex"} justifyContent={"space-between"} sx={{ mb: 2 }}>
            <Tooltip title={media.file_name || media.name || "-"} placement="top">
          <ReportTitle>{media.file_name || media.name}</ReportTitle>
          </Tooltip>
              <img src={CloseIcons}
                onClick={() => {
                  if (isSent) { unlink(media.id,"report") }
                  else {
                    const selectedMediaItems = [...mediaArray]
                    const index = selectedMediaItems.findIndex(el => el.file_id === media.file_id)
                    if (index !== -1) {
                      selectedMediaItems.splice(index, 1);
                      setMediaArray(selectedMediaItems);
                    }
                  }



                }}

style={{cursor:'pointer'}}
              />
            </Box>
            <ReportDocument>
              <img src={Documents} />
              <PdfBtn>PDF</PdfBtn>
            </ReportDocument>
            <FormControl fullWidth sx={{ minWidth: "130px" }} size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={media.status== "AVAILABLE"? DropdownAvailableIconButton:DropdownIconButton}
                value={media.status}
                onChange={(e)=>handleChange(e, media.file_id ||media.id)}
                sx={{
                  '.MuiOutlinedInput-root': {
                    borderColor: media.status== "AVAILABLE"? "#7ABA56":"#CC8552",
                  },
                  '.MuiSelect-select': {
                    color:media.status== "AVAILABLE"? "#7ABA56":"#CC8552",
                  },
                  ".MuiSelect-icon": {
                    top: 'calc(50% - 12px)',
                  },
                  ".MuiSelect-icon.MuiSelect-iconOpen": {
                    top: 'calc(50% - 17px)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: media.status== "AVAILABLE"? "#7ABA56":"#CC8552",
                    outline:"none", // Replace 'your-color' with the desired color
                  },
                  'hover': {
                    outline: 'none',
                    border: media.status== "AVAILABLE"? "#7ABA56":"#CC8552"
                  },
                  "fieldset": {
                    borderColor: media.status== "AVAILABLE"? "#7ABA56":"#CC8552",
                  },
                }}
              >
                <MenuItem value={"RESTRICTED"}>Restricted</MenuItem>
                <MenuItem value={"AVAILABLE"}>Available</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body1" sx={{fontSize:'10px', paddingTop:"8px"}}>
            {media.status == 'RESTRICTED' ? "This file will be only be available for doctors" : "This file will be available for patient and doctor to view"}
            </Typography>
          </ReportCard>
          </div>
        )
      }) : null}

    </ReportBox>
  );
}

export default ReportDetailsCard;
